'use client';

import { useSignIn } from '@clerk/nextjs';
import type { EmailLinkFactor } from '@clerk/types';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useForm } from 'react-hook-form';

import GoogleIcon from '@/components/icons/GoogleIcon';
import { Input } from '@/components/inputs/Input';
import { Route } from '@/constants/routes';

const SignInEmailForm = () => {
  const { isLoaded: isSignInLoaded, signIn } = useSignIn();
  const router = useRouter();
  const { handleSubmit, setError, formState, register } = useForm<{
    identifier: string;
  }>({
    defaultValues: {
      identifier: '',
    },
  });

  const getBaseUrl = () => {
    if (typeof window !== 'undefined') {
      return `${window.location.origin}`;
    }
    return '';
  };

  const handleInitialSubmit = handleSubmit(async (data) => {
    if (!isSignInLoaded) return;

    try {
      const signInAttempt = await signIn.create({
        identifier: data.identifier,
      });

      const emailFactor = signInAttempt.supportedFirstFactors.find(
        (factor): factor is EmailLinkFactor => factor.strategy === 'email_link',
      );

      if (!emailFactor || !emailFactor.emailAddressId) {
        throw new Error('Email address ID not found.');
      }

      sessionStorage.setItem('email', data.identifier);
      sessionStorage.setItem('emailAddressId', emailFactor.emailAddressId);

      await signIn.prepareFirstFactor({
        strategy: 'email_link',
        emailAddressId: emailFactor.emailAddressId,
        redirectUrl: `${getBaseUrl()}/sign-in/factor-one`,
      });

      router.push('/sign-in/factor-one');
    } catch (error: any) {
      if (error.errors && error.errors.length > 0) {
        setError('identifier', {
          type: 'manual',
          message:
            error.errors[0].longMessage || 'Sign-in failed. Please try again.',
        });
      } else {
        setError('identifier', {
          type: 'manual',
          message: error.message || 'An unexpected error occurred.',
        });
      }
    }
  });

  const signInWithOAuth = (strategy: 'oauth_google') => {
    if (!isSignInLoaded) return;
    signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: '/sign-in/sso-callback',
      redirectUrlComplete: '/dashboard',
    });
  };

  return (
    <form onSubmit={handleInitialSubmit} className="w-full space-y-8">
      <div className="space-y-2">
        <h2 className="text-2xl font-medium">
          Ready to connect advertisers and audience?
        </h2>
        <p className="text-stone-500">
          Enter your email to receive a sign-in link
        </p>
      </div>

      <Input
        {...register('identifier')}
        label="Email Address"
        placeholder="Type here"
        errorMessage={formState.errors.identifier?.message}
      />

      <button
        type="submit"
        className="btn btn-primary w-full bg-primary text-white !shadow-none hover:bg-blue-700"
      >
        Send Sign-In Link
      </button>

      <div className="text-center text-stone-300">or</div>

      <button
        type="button"
        onClick={() => signInWithOAuth('oauth_google')}
        className="btn btn-outline btn-primary flex w-full"
      >
        <GoogleIcon />
        Sign In with Google
      </button>

      <div className="flex gap-2">
        <p className="text-sm text-neutral-500">Don’t have an account?</p>
        <Link href={Route.SignUp} className="text-sm text-primary underline">
          Sign up
        </Link>
      </div>
    </form>
  );
};

export default SignInEmailForm;
