import * as React from 'react';

function GoogleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={24} fill="none" {...props}>
      <path
        d="M12.5 2.6c2.251 0 4.312.797 5.931 2.12l-1.992 1.992A6.555 6.555 0 0012.5 5.4a6.6 6.6 0 000 13.2c2.875 0 5.31-1.84 6.217-4.4l.284-.8H13.1v-2.8h7.8v.042H21.8A9.4 9.4 0 1112.5 2.6z"
        fill="#FFC107"
        stroke="#4569C7"
        strokeWidth={1.2}
      />
      <path
        d="M3.653 7.346L6.94 9.755A5.997 5.997 0 0112.5 6c1.53 0 2.921.577 3.98 1.52l2.83-2.829A9.954 9.954 0 0012.5 2a9.994 9.994 0 00-8.847 5.346z"
        fill="#EB5757"
      />
      <path
        d="M12.5 22c2.583 0 4.93-.988 6.705-2.596l-3.095-2.619A5.955 5.955 0 0112.5 18a5.997 5.997 0 01-5.641-3.973L3.598 16.54C5.253 19.778 8.614 22 12.5 22z"
        fill="#4CAF50"
      />
      <path
        d="M22.305 10.041H21.5V10h-9v4h5.651a6.02 6.02 0 01-2.043 2.785h.002l3.095 2.619C18.985 19.602 22.5 17 22.5 12c0-.67-.069-1.325-.195-1.959z"
        fill="#1976D2"
      />
    </svg>
  );
}

const MemoGoogleIcon = React.memo(GoogleIcon);
export default MemoGoogleIcon;
