'use client';

import Image from 'next/image';
import React from 'react';

import ClerkLogo from '@/components/icons/ClerkLogo';
import Logo from '@/components/icons/Logo';

interface IProps {
  children: React.ReactNode;
  isClerkVisible?: boolean;
}

export const AuthTemplate: React.FC<IProps> = ({
  children,
  isClerkVisible,
}) => {
  return (
    <div className="flex min-h-screen w-full">
      <div className="relative flex flex-1 flex-col items-center justify-center pb-20">
        <div className="mx-auto max-w-lg">
          <Logo className="mb-16" />
          {children}
        </div>

        {isClerkVisible && (
          <div className="absolute bottom-10 left-1/2 flex -translate-x-1/2 items-center">
            <p className="mr-2 text-stone-500">Secured By</p>
            <ClerkLogo />
          </div>
        )}
      </div>

      <div className="relative hidden flex-1 lg:block">
        <Image
          src="/auth-image.png"
          alt="auth image"
          fill
          sizes="(max-width: 1024px) 100vw, 50vw"
          style={{ objectFit: 'cover' }}
          priority
        />
      </div>
    </div>
  );
};
