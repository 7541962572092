'use client';

import { useClerk, useSignIn } from '@clerk/nextjs';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { getBaseUrl } from '@/utils/Helpers';

type ClerkStatus = 'verified' | 'failed' | 'expired' | 'client_mismatch';

const SignInVerificationForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { signIn, isLoaded } = useSignIn();
  const { handleEmailLinkVerification } = useClerk();
  const router = useRouter();
  const [emailAddressId, setEmailAddressId] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const searchParams = useSearchParams();
  const clerkStatus = searchParams.get('__clerk_status') as ClerkStatus | null;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setEmailAddressId(sessionStorage.getItem('emailAddressId'));
      setEmail(sessionStorage.getItem('email'));
    }
  }, []);

  useEffect(() => {
    async function verify() {
      try {
        await handleEmailLinkVerification({
          redirectUrl: `${getBaseUrl()}/sign-in/factor-one`,
          redirectUrlComplete: `${getBaseUrl()}/dashboard`,
        });
      } catch (err) {
        // error handling
      }
    }
    verify();
  }, [handleEmailLinkVerification]);

  const resendEmail = async () => {
    if (!isLoaded) {
      return;
    }
    if (!emailAddressId) {
      router.replace('sign-in');
      return;
    }
    setIsSubmitting(true);

    try {
      await signIn.prepareFirstFactor({
        strategy: 'email_link',
        emailAddressId,
        redirectUrl: `${getBaseUrl()}/sign-in/factor-one`,
      });

      setIsSubmitting(false);
    } catch (error) {
      // error handling
    }
  };

  const getMessage = (status: string | null) => {
    if (status === 'failed') return 'Email link verification failed.';
    if (status === 'expired')
      return 'Email link expired. Please request a new link.';
    if (status === 'verified')
      return 'Successfully signed in. You will be redirected shortly.';
    return 'Use the verification link sent to your email';
  };

  const isResendVisible = clerkStatus !== 'verified' || clerkStatus === null;

  return (
    <div>
      <p>{getMessage(clerkStatus)}</p>
      <p className="font-semibold">{email}</p>

      {isResendVisible && (
        <p className="mt-4 text-sm text-gray-500">
          Didn’t receive a link?
          <button
            onClick={resendEmail}
            type="button"
            disabled={isSubmitting}
            className="btn btn-link text-sm font-semibold text-primary"
          >
            Resend Email
          </button>
        </p>
      )}
    </div>
  );
};

export default SignInVerificationForm;
