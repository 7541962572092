export enum Route {
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Welcome = '/welcome',
  Dashboard = '/dashboard',
  Advertisers = '/advertisers',
  Creatives = '/creatives',
  Campaigns = '/campaigns',
  AdvertisersReporting = '/reporting/advertisers',
  CreateCampaign = '/campaigns/create',
  Reporting = '/reporting',
}
