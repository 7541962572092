'use client';

import { usePathname } from 'next/navigation';

import SignInEmailForm from './SignInEmailForm';
import SignInVerificationForm from './SignInVerificationForm';

const SignInForm = () => {
  const pathname = usePathname();
  const isVerificationStep = pathname.includes('factor-one');

  return (
    <div>
      {!isVerificationStep ? <SignInEmailForm /> : <SignInVerificationForm />}
    </div>
  );
};

export default SignInForm;
