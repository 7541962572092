import React, { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  label?: string;
  wrapperClassName?: string;
  iconButton?: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ errorMessage, label, wrapperClassName, iconButton, ...props }, ref) => {
    return (
      <div className={`space-y-1 ${wrapperClassName}`}>
        {label && (
          <label htmlFor={props.name} className="text-sm text-stone-500">
            {label} {props.required && <span className="text-red-500">*</span>}
          </label>
        )}
        <div className="relative w-full">
          <input
            {...props}
            ref={ref}
            className={`input input-bordered w-full border-gray-300 text-sm ${props.className}`}
            id={props.name}
          />
          {iconButton && (
            <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
              {iconButton}
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="mt-1 text-sm text-red-500">{errorMessage}</p>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
